// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-person-tsx": () => import("./../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-practice-area-tsx": () => import("./../src/templates/practice-area.tsx" /* webpackChunkName: "component---src-templates-practice-area-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-medarbetare-tsx": () => import("./../src/pages/medarbetare.tsx" /* webpackChunkName: "component---src-pages-medarbetare-tsx" */),
  "component---src-pages-personuppgiftspolicy-tsx": () => import("./../src/pages/personuppgiftspolicy.tsx" /* webpackChunkName: "component---src-pages-personuppgiftspolicy-tsx" */),
  "component---src-pages-verksamhetsomraden-tsx": () => import("./../src/pages/verksamhetsomraden.tsx" /* webpackChunkName: "component---src-pages-verksamhetsomraden-tsx" */)
}

